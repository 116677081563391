export default {
  computed: {
    snowSafetyConsole() {
      let product = this.products.find(p => p.type === 'snowSafetyConsole')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      // Calculate amount
      // AFFECTS NUM TRACKTILES
      let rooftileCC = this.rooftile.cc
      let maxDistanceConsole = this.rooftile.maxDistanceConsole



      let distanceConsole = this.maxDistance < maxDistanceConsole ?
        maxDistanceConsole / 1000 :
        Math.floor(this.maxDistance / rooftileCC) * rooftileCC / 1000

      // From productCalcUtil imported in summarystep
      let rows = this.rowsSnowprotection

      let amount = 0
      let roofSafetyLengths = this.selections.roofSafetyLengths.value
      let snowProtectionLength = this.snowProtection['length'] / 1000

  
//new calc 20240528
roofSafetyLengths.forEach((distance) => {
        if (!isNaN(Number(distance))) {
          let length = Math.floor(Number(distance) / snowProtectionLength) * snowProtectionLength //Räknar ut hur många safe som behövs
          let lengthDiff = distance - length //Räknar ut diffen safe
          let totalSafe = 0
          if (lengthDiff > 0.5) {
            totalSafe += Math.floor(Number(distance) / snowProtectionLength) + 1; //Ger antal safe
          } else {
            totalSafe += Math.floor(Number(distance) / snowProtectionLength); // Ger antal safe
          }
          let totalconsole = (totalSafe * snowProtectionLength).toFixed(2) // Räknar med värdet som antalsafe gånger konsollängd Ta bort decimaler (spara 2)
          amount += Math.ceil((totalconsole) / (distanceConsole)) + 1 //Delar antal konsoler med längden avrundat uppåt
        }
      })
      amount = amount * rows

      let bigpack = this.bigpack(fullProduct, amount)

      // Add properties
      fullProduct.amount = amount
      fullProduct.singlepack = bigpack[0]
      fullProduct.bigpack = bigpack[1]
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
        fullProduct.sort = 2

      return fullProduct
    }
  },
  methods: {}
}