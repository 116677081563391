import axios from 'axios'
import VueCookies from 'vue-cookies'
import moment from 'moment'

moment.locale('sv')

const CancelToken = axios.CancelToken;
let cancel = {};

const instance = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT_API_BASE_URL,
})

// Intercept request and refresh Auth header
instance.interceptors.request.use((config) => {
  let token = VueCookies.get('benders_logged_in')
  if (!token) {
    const urlParams = new URLSearchParams(window.location.search)
    token = urlParams.get('session')
  }
  config.headers.Authorization = `Bearer ${token}`
  return config;
})

export function getArticlesByArtNr(artnrs, skip_default_filters = false) {
  const _uid = (this) ? this._uid : "";
  const filter = JSON.stringify({
    'query': {
      'bool': {
        'must': [
          {
            'terms': {
              'ArtNr.raw': artnrs
            }
          }
        ]
      }
    }
  });
  return instance.get(`articles?skip_halfpallet_filter=true&skip_default_filters=${skip_default_filters || ''}`, {
    params: { filter: filter, size: artnrs.length },
    cancelToken: new CancelToken(() => { cancel[`articles${_uid}`] })
  })
    .then(r =>
      r.data.hits.hits.reduce((obj, data) => { obj[data._source.id] = data._source; return obj }, {}))
}