export default {
  computed:{  
    roofHatch () {
      let product = this.products.find(p => p.type === 'roofHatchSafety')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      
      let amount = this.selections.roofHatchSafetyAmount.value
      fullProduct.amount = amount

      fullProduct.singlepack = amount
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
      fullProduct.sort = 6

      return fullProduct
    },
  },
  methods: {}
}