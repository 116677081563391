export default {
  computed: {
    roofBridgeConsole() {
      let product = this.products.find(p => p.type === 'roofBridgeConsole')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      // Calculate amount
      let multiplier = this.roofMultiplier / 1000

      let roofBridgeLengths = this.selections.roofBridgeLengths.value

      let normalLength = this.products.find(p => p.type === 'roofBridge').length / 1000

      let amount = this.amountAccessories(roofBridgeLengths, normalLength, multiplier)

      let bigpack = this.bigpack(fullProduct, amount)

      // Add properties
      fullProduct.amount = amount
      fullProduct.singlepack = bigpack[0]
      fullProduct.bigpack = bigpack[1]
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st',
        fullProduct.sort = 5

      return fullProduct
    }
  },
  methods: {
    amountAccessories(lengthsArr = [], productLength = 1, multiplier = 0) {
      let total = 0;
      let lengthLong = 2.1;
      let lengthShort = 1.05;
      //let amountLong = 0;
      //let amountShort = 0;
      let tolerance = 0.3;

      lengthsArr.forEach((distance) => {
        if (!isNaN(Number(distance))) {
          // Calculate total products for the current distance
          let amountLong = 0;
          let amountShort = 0;
          let numDistance = Number(distance);

          while (numDistance > 3.15) { // Minska numDistance med lengthLong tills det är mindre än eller lika med 3.15
            amountLong++;
            numDistance -= lengthLong;
          }

          if (numDistance <= lengthShort + tolerance) {  // Kontrollera om numDistance är mindre än eller lika med lengthShort + tolerance
            amountShort++;
          } else if (numDistance <= lengthLong + tolerance) { // Kontrollera om numDistance är mindre än eller lika med lengthLong + tolerance
            amountLong++;
          } else if (numDistance <= lengthLong + lengthShort + tolerance) { // Kontrollera om numDistance är mindre än eller lika med lengthLong + lengthShort + tolerance
            amountLong++;
            amountShort++;
          }

          // Calculate total length for current product count
          let totalLength = (amountLong * productLength) + (amountShort * 1.05);
          console.log("Initial totalLength:", totalLength);

          // Round totalLength to one decimal place
          totalLength = Number(totalLength.toFixed(1));
          console.log("Rounded totalLength:", totalLength);

          // Calculate total using the adjusted total length
          let calculatedValue = totalLength / multiplier
          calculatedValue = Number(calculatedValue.toFixed(2))
          let calculatedValueCeil = Math.ceil(calculatedValue)
          console.log("Calculated value after division and rounding:", calculatedValue);

          total += calculatedValueCeil + 1;
          console.log("Final total:", total);


        }
      });

      return total;
    },

  }
}