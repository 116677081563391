export default {
  computed:{
    tracktiles () {
      let rooftile = this.selections.rooftile.value
      let products = this.products.filter(p => p.type === 'tracktile' && p.series === rooftile)
      let fullProduct = []
      
      // Calculate amount      
      let amount = 0
      let consoles = 
        this.snowSafetyConsole.amount +
        this.roofBridgeConsole.amount +
        this.roofRailConsole.amount
        
      let roofHatchConsoles = this.roofHatchConsole.amount
        
      switch(rooftile) {
        case 'Carisma platt':
        case 'Hansa 1-kupig':
        case 'Tvilling 2-kupig':
        case 'Piano platt':
        amount = consoles
      break
        default:
        // Exklusiv 1-kupig
        // Palema 2-kupig
        amount = consoles * 2
      }

      amount += roofHatchConsoles * 8


      products.forEach((product, index) => {
        let variant = product.variants.find(v => v.treatment == this.selections.rooftileTreatment.value && v.color == this.selections.rooftileColor.value)  
        if (variant === undefined) return 

        fullProduct.push({...product, ...variant})
        delete fullProduct[index].variants

        fullProduct.amount = amount
      
        fullProduct[index].singlepack = amount
        fullProduct[index].bigpack = null
        // fullProduct[index].pallets =  1,
        // fullProduct[index].totalWeight =  2,
        // fullProduct[index].values =  1,
        fullProduct[index].unit = 'st',
        fullProduct[index].sort = 99
      })
      return fullProduct
    }
  },
  methods: {}
}

