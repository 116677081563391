export default {
  computed: {
    snowProtection() {
      let product = this.products.find(p => p.type === 'snowSafety')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = { ...product, ...variant }
      delete fullProduct.variants;

      let amount = this.amountOfProducts(this.selections.roofSafetyLengths.value, fullProduct["length"] / 1000)

      // From productCalcUtil imported in summarystep
      let rows = this.rowsSnowprotection

      fullProduct.amount = amount * rows

      fullProduct.singlepack = amount * rows
      fullProduct.bigpack = null
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st'
      fullProduct.sort = 1

      return fullProduct
    }
  },
  methods: {}
}